import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import ContentServices from "../components/Services/ContentServices3";
import BaseLayout from "../components/global/BaseLayout";

function Services() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Painting">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Painting"
                    Subheader="Painting"
                    bgimg={`${rpdata?.stock?.[5]}`}
                />
                <ContentServices />
            </div>
        </BaseLayout>
    );
}

export default Services;
